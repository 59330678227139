<template>
  <div class="mb-4">
    <h4
      class="text-uppercase mb-3 primary--text"
      v-text="value.name"
    />

    <div class="pl-md-4">
      <v-chip-group class="column">
        <v-chip
          v-for="(skill, i) in value.keywords"
          :key="i"
          background-color="grey darken-4"
        >
          {{ skill }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          category: '',
          skills: [],
        }),
      },
    },
  }
</script>
